export const EDUCATIONEXPERIENCE = [
  {
    name: 'Frontend Engineer at Wildkard',
    year: '2021 - 2022',
    desc:
      'Worked on the creation of a social media for sports teams. The app encouraged young people to keep up with their sports, and more.',
    exp: true,
  },
  {
    name: 'Frontend Engineer at mrgn.ai',
    year: '2021 - 2021',
    desc: 'Creating the next Online Financial Budgeting Tool.',
    exp: true,
  },
  {
    name: 'Frontend Engineer at Monk Medical/Keco Group Inc.',
    year: '2021 - 2021',
    desc:
      'Created and maintains the commercial website of the Monk Medical Company. Created, from the ground up, UI elements and the User Experience.',
    exp: true,
  },
  {
    name: 'Frontend Engineer at EltaVR',
    year: '2020 - 2021',
    desc:
      'Completely recreated the website of Elta VR, using React and other libraries. Worked on the pages; sign-in/up, home,analytics, organization, profile. This website is used to create 360° tours of buildings to have virtual tours in them. ',
    exp: true,
  },
  {
    name: 'Founder and Software Engineer at hymn',
    year: '2018 - 2020',
    desc:
      'Worked on the music service hymn. Managed the start-up andz worked on the Front-End interfaces as well as API integration. The start-up was selected to the Garage Residency program at Northwestern University. The APIs are displayed on the right.',
    exp: true,
  },
  {
    name: 'IEEE Northwestern University Chapter',
    year: '2017 - 2020',
    desc:
      'Served in various different positions including, Social Chair, Project Manager and President. Created a game using Unity, and two applications using React Native.',
  },
  {
    name: 'Peer Mentor at Northwestern University',
    year: '2017 - 2020',
    desc:
      "Peer mentored in the classes 'Fundamentals of Computer Programming I & II', 'Introduction to Artificial Intelligence' and Human Computer Interaction at Northwestern University.",
    exp: true,
  },
  {
    name: 'Northwestern University',
    year: '2016 - 2020',
    desc:
      "Graduated from Northwestern University's McCormick School of Engineering with a Bachelor of Science in Computer Science & Psychology.",
  },

  {
    name: 'Resident at The Garage at Northwestern University',
    year: '2019 - 2019',
    desc:
      "Managed the startup 'hymn' as a part of the residency program at the Garage.",
  },
  {
    name: 'Data Scientist & Frontend Engineering Associate at Anodot',
    year: '2019 - 2019',
    desc:
      'Created a UI, using React, R and JavaScript, that helped interpret the anomaly data easily and made the process of interpreting more accessible and ergonomically advanced. This UI was used by the Sales Engineers to help explain the main product of Anodot, the anomaly detection, to the customers.',
    exp: true,
  },
  {
    name: 'Co-Founder and Software Engineer at Hearo',
    year: '2018 - 2018',
    desc:
      'Was part of the team that created the transcription app Hearo. Worked on the navigation part, the calling part and several other front-end side developments of the application. The app is displayed on the right.',
    exp: true,
  },
  {
    name: 'Front-End Engineering Intern at Inveon',
    year: '2017 - 2017',
    desc:
      'Worked on translating an application to React Native and bugfixes, added several other features including Google Firebase Analytics.',
    exp: true,
  },
  {
    name: 'Ulus Private Jewish Schools',
    year: '2012 - 2016',
    desc:
      'Finished in the top 5% of his year as the student body president. Completed the science track.',
  },
  {
    name: 'Web Developer Intern at Pixelsoft Office',
    year: '2014 - 2014',
    desc:
      'Worked on the creation the Coca Cola machine interface at Turkey and editted websites the company worked on.',
    exp: true,
  },
];
