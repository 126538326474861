export const TEXT_SIZES = {
  DEFAULT: 20,
  LABEL: 15,
  SMALL: 10,
  MEDIUM: 25,
  MEDIUM_LARGE: 50,
  LARGE: 80,
  LARGER: 100,
  EXTRA_LARGE: 125,
};

export const ICON_SIZES = {
  DEFAULT: 10,
  SMALL: 10,
  LARGE: 50,
};
