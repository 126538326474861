const NORMAL_COLOR = '#1E272E';
const LIGHTER_COLOR = '#53616B';
const LIGHT_COLOR = '#38444D';
const DARK_COLOR = '#132029';
const DARK_COMPLEMENT = '#3A4954';
const DARKER_COLOR = '#0A1720';
const LIGHT_VIBRANT_COLOR = '#03B3ED';
const VIBRANT_COLOR = '#0184AF';
const LIGHT_DARKER = '#014E68';
const LIGHT_LIGHTER = '#016687';
const DARK_VIBRANT_COLOR = '#575fcf';
const DARKER_TEXT = '#a2a0a0';
const YELLOW_FLU = `#ffd32a`;
const WHITE = '#fff';
// const BLACK = '#000';

export const TEXT_COLORS = {
  DEFAULT: WHITE,
  DISABLED: DARKER_TEXT,
  DESCRIPTION: LIGHTER_COLOR,
  TITLE: YELLOW_FLU,
};

export const LIGHT_TEXT = {
  DEFAULT: WHITE,
  DISABLED: DARKER_TEXT,
  DESCRIPTION: WHITE,
  TITLE: YELLOW_FLU,
};

export const BACKGROUND_COLORS = {
  DEFAULT: DARK_COLOR,
  COMPLEMENT: DARK_COMPLEMENT,
  LIGHT: NORMAL_COLOR,
  LIGHTER: LIGHT_COLOR,
  DARKEST: DARKER_COLOR,
  LIGHTEST: LIGHTER_COLOR,
  LIGHT_VIBRANT: LIGHT_VIBRANT_COLOR,
  VIBRANT: VIBRANT_COLOR,
  DARK_VIBRANT: DARK_VIBRANT_COLOR,
  FLU: YELLOW_FLU,
};

export const LIGHT_BACKGROUND = {
  DEFAULT: VIBRANT_COLOR,
  COMPLEMENT: LIGHT_VIBRANT_COLOR,
  LIGHT: LIGHT_LIGHTER,
  LIGHTER: LIGHTER_COLOR,
  DARKEST: LIGHT_DARKER,
  LIGHTEST: LIGHT_VIBRANT_COLOR,
  LIGHT_VIBRANT: LIGHT_VIBRANT_COLOR,
  VIBRANT: DARK_COLOR,
  DARK_VIBRANT: DARK_VIBRANT_COLOR,
  FLU: YELLOW_FLU,
};
